import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, user, children }) => {
   
    if ( user?.isAdmin !== true) {
        return children;
      }
     

  return <Navigate to="/NotAuthorised" replace />;
};

export default ProtectedRoute;