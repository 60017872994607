import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminConfirmation = () => {
  const [payments, setPayments] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState({});
  const [error, setError] = useState(''); // State to hold error messages

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get('https://backend.makejahunt.co.ke/api/pending-payments');
        setPayments(response.data);
      } catch (error) {
        console.error('Error fetching payments:', error);
        setError('Failed to fetch payments.');
      }
    };

    fetchPayments();
  }, []);

  const handleConfirmPayment = async (paymentId, houseId) => {
    try {
      const response = await axios.post('https://backend.makejahunt.co.ke/api/confirm-payment', {
        paymentId,
        houseId,
      });
      alert(response.data.message);
      setPayments(payments.filter((payment) => payment.id !== paymentId));
      setSelectedPayments({ ...selectedPayments, [paymentId]: true });
    } catch (error) {
      console.error('Error confirming payment:', error);
      setError(error.response ? error.response.data.message : 'Error confirming payment');
    }
  };

  const handleNotConfirmedPayment = async (paymentId) => {
    setError(''); // Clear previous errors
    try {
      const response = await axios.post(`https://backend.makejahunt.co.ke/api/not-confirmed-payment/${paymentId}`);
      alert(response.data.message); // Display success message

      setPayments(payments.filter(payment => payment.id !== paymentId));
      setSelectedPayments({ ...selectedPayments, [paymentId]: true });
    } catch (error) {
      console.error('Error marking payment as not found:', error);
      if (error.response) {
        setError(error.response.data.message || 'Error marking payment as not found');
      } else if (error.request) {
        setError('No response from server');
      } else {
        setError('Error marking payment as not found');
      }
    }
  };

  return (
    <div className="admin-confirmation">
      <h1>Admin Payment Confirmation</h1>
      {error && <p className="error-message">{error}</p>} {/* Display error messages */}
      {payments.length === 0 ? (
        <p>No pending payments to confirm.</p>
      ) : (
        <table className="payment-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Amount Paid</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {payments.map(payment => (
              <tr key={payment.id}>
                <td>{payment.name}</td>
                <td>{payment.phoneNumber}</td>
                <td>${payment.amountPaid}</td>
                <td>
                  {!selectedPayments[payment.id] && (
                    <>
                      <button onClick={() => handleConfirmPayment(payment.id, payment.houseId)} className="confirm-button">
                        Confirm Payment
                      </button>
                      <button onClick={() => handleNotConfirmedPayment(payment.id)} className="not-confirmed-button">
                        Not Confirmed
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminConfirmation;