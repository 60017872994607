import React from 'react';
import { useNavigate } from 'react-router-dom';


const AdminSelectionPage = () => {
  const navigate = useNavigate();

  const handlePageSelection = (page) => {
    navigate(`/${page}`);
  };

  return (
    <div className="admin-selection">
      <h1>Admin Dashboard</h1>
      <div className="selection-buttons">
        <button onClick={() => handlePageSelection('adminconfirmation')} className="selection-button">
          Admin Confirmation
        </button>
        <button onClick={() => handlePageSelection('addhouse')} className="selection-button">
          Add House
        </button>
        <button onClick={() => handlePageSelection('attendancePage')} className="selection-button">
          Attendance
        </button>
      </div>
    </div>
  );
};

export default AdminSelectionPage;