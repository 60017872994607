import React from 'react';


const WebServices = () => {
  return (
    <div className="web-services-container">
      <header className="web-services-banner">
        <h1>Our Web Services</h1>
      </header>

      <div className="web-services-content">
        <section className="services-section">
          <h2>Frontend Development</h2>
          <p>
            We craft visually stunning and responsive websites using the latest frontend technologies. 
            Our expertise includes:
          </p>
          <ul>
            <li><ion-icon name="arrow-forward-outline"></ion-icon> React.js and Next.js for dynamic user interfaces</li>
            <li><ion-icon name="arrow-forward-outline"></ion-icon> Responsive and mobile-first designs using HTML5, CSS3, and JavaScript</li>
            <li><ion-icon name="arrow-forward-outline"></ion-icon> Animations and interactive features with CSS and JavaScript libraries</li>
            <li><ion-icon name="arrow-forward-outline"></ion-icon> Cross-browser compatibility and performance optimization</li>
          </ul>
        </section>

        <section className="services-section">
          <h2>Backend Development</h2>
          <p>
            Our backend development services ensure your web application is fast, secure, and scalable. 
            We specialize in:
          </p>
          <ul>
            <li><ion-icon name="arrow-forward-outline"></ion-icon> Node.js and Express.js for high-performance server-side applications</li>
            <li><ion-icon name="arrow-forward-outline"></ion-icon> Database design and management with MongoDB, MySQL, and PostgreSQL</li>
            <li><ion-icon name="arrow-forward-outline"></ion-icon> RESTful API development and integration</li>
            <li><ion-icon name="arrow-forward-outline"></ion-icon> User authentication and security best practices</li>
          </ul>
        </section>

        <section className="contact-section">
          <h2>Contact Us</h2>
          <div className="contact-info">
            <div className="contact-item">
              <ion-icon name="mail-outline"></ion-icon>
              <p>Email: <a href="mailto:makejahunt254@gmail.com">makejahunt254@gmail.com</a></p>
            </div>
            <div className="contact-item">
              <ion-icon name="call-outline"></ion-icon>
              <p>Phone: <a href="tel:+254714234491">+254-714234491</a></p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default WebServices;
