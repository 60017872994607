import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      setMessageType('error');
      return;
    }

    try {
      const response = await axios.post('https://backend.makejahunt.co.ke/api/register', {
        firstname,
        email,
        username,
        password,
      });

      setMessage(response.data.message || 'Registration successful, proceed to login');
      setMessageType('success');

      setFirstname('');
      setEmail('');
      setUsername('');
      setPassword('');
      setConfirmPassword('');
    } catch (error) {
      const serverMessage = error.response?.data?.message;

      if (serverMessage && serverMessage.includes('Username already exists')) {
        setMessage('Username already exists');
      } else if (serverMessage && serverMessage.includes('Email already exists')) {
        setMessage('Email already exists');
      } else {
        setMessage('An error occurred, please try again');
      }

      setMessageType('error');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <div className="loginregisterbody">
      <div className="wrapper">
        <div className="form-box">
          <div className="register-container" id="register">
            <div className="top">
              <span onClick={() => navigate('/login')} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>Have an account? Login</span>
              <h2>Sign Up</h2>
              <div className="login-message">
                {message && (
                  <p className={messageType === 'success' ? 'message success' : 'message error'}>
                    {message}
                  </p>
                )}
              </div>
            </div>

            <form onSubmit={handleRegister}>
              <div className="two-forms">
                <div className="input-box">
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    required
                  />
                  <i className="bx bx-user"></i>
                </div>
                <div className="input-box">
                  <input
                    type="email"
                    className="input-field"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <i className="bx bx-user"></i>
                </div>
              </div>
              <div className="input-box">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Username or Phone Number"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="input-box">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  className="input-field"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <i className="bx bx-lock-alt"></i>
                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                >
                  <ion-icon name={passwordVisible ? 'eye-off-outline' : 'eye-outline'}></ion-icon>
                </button>
              </div>
              <div className="input-box">
                <input
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  className="input-field"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <i className="bx bx-lock-alt"></i>
                <button
                  type="button"
                  className="toggle-password"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <ion-icon name={confirmPasswordVisible ? 'eye-off-outline' : 'eye-outline'}></ion-icon>
                </button>
              </div>

              <div className="input-box">
                <button type="submit" className="submit" value="Register">Sign up</button>
              </div>
              <div className="two-col">
                <div className="one">
                  <input type="checkbox" id="register-check" />
                  <label htmlFor="register-check"> Remember Me</label>
                </div>
                <div className="two">
                  <span onClick={() => navigate('/Terms')} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>
                    Terms and conditions
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

