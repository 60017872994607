import React from 'react'

const Terms = () => {
  return (
    <div className='terms'>
      <p>
      <h1>Terms and conditions</h1>
      Welcome to our website! These terms and conditions outline the rules and regulations for the use of our website.<br/>
      </p>
      <p>
      <h2> Introduction</h2>  
            please read the terms and conditions carefully before using our website.Your access to and use of the service is conditioned on your acceptance of and compliance with these terms.
            These terms apply to all visitors, users and others who access or use the service.<br/>
            By accessing or using the service you agree to be bound by these terms. If you disagree with any part of the terms then you may not access the service.<br/>
            </p>
            <p>
            <h2>Termination</h2>
            We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.<br/>
            All provisions of the terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.<br/>
          </p>
          <p>
            <h2>Governing Law</h2>
            These terms and conditions are governed by and construed in accordance with the laws of kenya, as of Article 40 of the Constitution of Kenya, and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
            "Protection of right to property. (b) in any part of Kenya. (b) to limit, or in any way restrict the enjoyment of any right under this Article on the basis of any of the grounds specified or contemplated in Article 27 (4)."
     </p>
 <h2> Use of the Service</h2>
 <p>
• Eligibility: You must be at least 18 years old to use our Service. 
• Account Registration: When you create an account with us, you must provide us with accurate, complete, and current information. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account. 
• User Conduct: You agree not to use the Service for any unlawful purpose or in any way that might harm, damage, or disparage any other party. Prohibited activities include, but are not limited to, posting false or misleading listings, using the Service to harass or discriminate, and any attempt to hack or disrupt the Service. 
</p>
<h2>Listings and Transactions </h2> 
<p>• Accuracy of Listings: Property listings on our Website must be accurate and not misleading. We are not responsible for any inaccuracies in the listings. 
• Rental Agreements: Any rental agreements made between landlords and tenants through the Service are solely between those parties. We are not a party to any rental agreement and are not responsible for the performance of either party. 
• Payments: If our Service includes payment processing, you agree to use the payment service in accordance with our Payment Terms, and you understand that we are not responsible for any issues related to payment processing. 
• Refund: please note that there are no refunds once payment is done unless there is a overpayment of the said amount. The refund will only include the extra payment and not the whole amount 
</p>

<h2>Intellectual Property </h2> 
<p>The Service and its original content, features, and functionality are and will remain the exclusive property of Makejahunt Housing and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of  Makejahunt Housing, </p>

<h2>Termination </h2> 
We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service. 

<h2>Limitation of Liability </h2>
<p>
In no event shall Makejahunt Housing nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage. 
</p>

<h2> Changes</h2> 
<p>
We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.
</p>


If you do not agree to the new terms, please stop using the Service. 
<h2>contact us</h2>
If you have any questions about these Terms, please contact us at  makejahunt@gmail.com  
Note: Makenjahunt is the sole business name

    </div>
  )
}

export default Terms

