import React from 'react';

const SecondHandItems = () => {
  return (
    <div className="second-hand-container">
      <h1>STILL COOKING THIS PAGE!!</h1>
      <header className="second-hand-banner">
        <h1>Second Hand Items</h1>
      </header>
      <div className="second-hand-content">
        <section className="buy-section">
          <h2>Buy Second Hand Items</h2>
          <p>Explore a wide range of second-hand items at great prices. From electronics to furniture, find everything you need here.</p>
          <button className="buy-button">Shop Now</button>
        </section>
        <section className="sell-section">
          <h2>Sell Second Hand Items</h2>
          <p>Got items to sell? List them here and reach a wide audience of potential buyers.</p>
          <button className="sell-button">Sell Now</button>
        </section>
      </div>
    </div>
  );
};

export default SecondHandItems;
