import React, { useState } from 'react';
import axios from 'axios';

const AddHouse = () => {
  const [dlocation, setDlocation] = useState('');
  const [location, setLocation] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [rent, setRent] = useState('');
  const [landlordnumber, setLandlordnumber] = useState('');
  const [name, setName] = useState('');
  const [unitsAvailable, setUnitsAvailable] = useState('');
  const [size, setSize] = useState('');
  const [waterAvailability, setWaterAvailability] = useState('false');
  const [electricityAvailability, setElectricityAvailability] = useState('false');
  const [photos, setPhotos] = useState([]);
  const [video, setVideo] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
   const [houseData, setHouseData] = useState(null); // To hold the returned house data

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    const formData = new FormData();
    formData.append('location', location);
    formData.append('type', type);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('rent', rent);
    formData.append('dlocation', dlocation);
    formData.append('landlordNumber', landlordnumber);
    formData.append('name', name);
    formData.append('unitsAvailable', unitsAvailable);
    formData.append('size', size);
    formData.append('waterAvailability', waterAvailability === 'true');
    formData.append('electricityAvailability', electricityAvailability === 'true');
    photos.forEach(photo => {
      formData.append('photos', photo);
    });
    if (video) formData.append('video', video);

    try {
      const response = await axios.post('https://backend.makejahunt.co.ke/api/add-house', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }); 
    
      console.log('Response:', response); // Log the full response
      setHouseData(response.data); // Store the house data
      setMessage('House added successfully!');
    
      // Clear form fields after successful submission
      setLocation('');
      setType('');
      setDescription('');
      setPrice('');
      setRent('');
      setDlocation('');
      setLandlordnumber('');
      setName('');
      setUnitsAvailable('');
      setSize('');
      setWaterAvailability('false');
      setElectricityAvailability('false');
      setPhotos([]);
      setVideo(null);
    } catch (err) {
      console.error('Error:', err.response || err.message); // Log the error details
      setError(err.response ? 
        (typeof err.response.data === 'string' ? err.response.data : JSON.stringify(err.response.data)) 
        : 'Error adding house');
    }
  };

  const handleFileChange = (e) => {
    if (e.target.name === 'photos') {
      setPhotos(Array.from(e.target.files));
    } else if (e.target.name === 'video') {
      setVideo(e.target.files[0]);
    }
  };

  return (
    <div className="addhouse">
      <div className="container">
        <h2>Add House</h2>
        {message && <p className="message success">{message}</p>}
        {error && <p className="message error">{error}</p>}
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="rent">Rent:</label>
            <input
              type="number"
              id="rent"
              value={rent}
              onChange={(e) => setRent(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="landlordnumber">Landlord Number:</label>
            <input
              type="number"
              id="landlordnumber"
              value={landlordnumber}
              onChange={(e) => setLandlordnumber(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="location">Location:</label>
            <input
              type="text"
              id="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="d-location">d Location:</label>
            <input
            type="text"
            id="d-location"
            value={dlocation}
            onChange={(e) => setDlocation(e.target.value)}
            required
            />
            </div>
          <div>
            <label htmlFor="type">Type:</label>
            <select
              id="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="">Select type</option>
              <option value="bedsitter">Bedsitter</option>
              <option value="single room">Single Room</option>
              <option value="1 bedroom">1 Bedroom</option>
              <option value="2 bedroom">2 Bedroom</option>
            </select>
          </div>
          <div>
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="price">Price:</label>
            <input
              type="number"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="unitsAvailable">Units Available:</label>
            <input
              type="number"
              id="unitsAvailable"
              value={unitsAvailable}
              onChange={(e) => setUnitsAvailable(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="size">Size:</label>
            <input
              type="text"
              id="size"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Water Availability:</label>
            <label>
              <input
                type="radio"
                name="waterAvailability"
                value="true"
                checked={waterAvailability === 'true'}
                onChange={(e) => setWaterAvailability(e.target.value)}
              /> Yes
            </label>
            <label>
              <input
                type="radio"
                name="waterAvailability"
                value="false"
                checked={waterAvailability === 'false'}
                onChange={(e) => setWaterAvailability(e.target.value)}
              /> No
            </label>
          </div>
          <div>
            <label>Electricity Availability:</label>
            <label>
              <input
                type="radio"
                name="electricityAvailability"
                value="true"
                checked={electricityAvailability === 'true'}
                onChange={(e) => setElectricityAvailability(e.target.value)}
              /> Yes
            </label>
            <label>
              <input
                type="radio"
                name="electricityAvailability"
                value="false"
                checked={electricityAvailability === 'false'}
                onChange={(e) => setElectricityAvailability(e.target.value)}
              /> No
            </label>
          </div>
          <div>
            <label htmlFor="photos">Photos:</label>
            <input
              type="file"
              id="photos"
              name="photos"
              accept="image/*"
              multiple
              onChange={handleFileChange}
              required
            />
          </div>
          <div>
            <label htmlFor="video">Video:</label>
            <input
              type="file"
              id="video"
              name="video"
              accept="video/*"
              onChange={handleFileChange}
            />
          </div>
          <button type="submit">Add House</button>
        </form>

 {/* Display added house details */}
 {houseData && (
        <div className="house-details">
          <h3>Added House Details:</h3>
          <p><strong>Name:</strong> {houseData.name}</p>
          <p><strong>Location:</strong> {houseData.location}</p>
          <p><strong>d Location:</strong> {houseData.dlocation}</p>
          <p><strong>Rent:</strong> {houseData.rent}</p>
          <p><strong>Landlord Number:</strong> {houseData.landlordNumber}</p>
          <p><strong>Units Available:</strong> {houseData.unitsAvailable}</p>
          {/* Display other houseData fields as needed */}
        </div>
      )}


        {message && <p className="message success">{typeof message === 'string' ? message : JSON.stringify(message)}</p>}
        {error && <p className="message error">{typeof error === 'string' ? error : JSON.stringify(error)}</p>}
      </div>
    </div>
  );
};

export default AddHouse;