import React from 'react';

const NotAuthorized = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Access Denied</h1>
      <p>You do not have permission to view this page.</p>
    </div>
  );
};

export default NotAuthorized;