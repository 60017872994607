import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { houseId, price } = location.state || {};

  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('phoneNumber') || '');
  const [amountPaid, setAmountPaid] = useState(localStorage.getItem('amountPaid') || '');
  const [timeLeft, setTimeLeft] = useState(() => {
    const storedExpiration = localStorage.getItem('expirationTime');
    const currentTime = Date.now();
    return storedExpiration ? Math.max(0, Math.floor((storedExpiration - currentTime) / 1000)) : 300;
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentId, setPaymentId] = useState(localStorage.getItem('paymentId') || null);
  const [paymentStatus, setPaymentStatus] = useState(localStorage.getItem('paymentStatus') || '');

  useEffect(() => {
    if (!houseId || !price) {
      navigate('/'); // Redirect to home if houseId or price is missing
    }
  }, [houseId, price, navigate]);

  // Set expiration time if it doesn't exist
  useEffect(() => {
    if (!localStorage.getItem('expirationTime')) {
      const expirationTime = Date.now() + 300000; // Set expiration time to 5 minutes from now
      localStorage.setItem('expirationTime', expirationTime);
    }
  }, []);

  // Timer for redirection when time runs out
  useEffect(() => {
    if (timeLeft <= 0) {
      navigate('/'); // Redirect when timer runs out
      localStorage.removeItem('expirationTime'); // Clear expiration time
      localStorage.removeItem('name');
      localStorage.removeItem('phoneNumber');
      localStorage.removeItem('amountPaid');
      localStorage.removeItem('paymentId');
      localStorage.removeItem('paymentStatus');
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, navigate]);

  // Polling for payment status
  useEffect(() => {
    if (paymentId && !paymentStatus) {
      const interval = setInterval(async () => {
        try {
          const response = await axios.get(`https://backend.makejahunt.co.ke/api/payment-status/${paymentId}`);
          const { status } = response.data;

          if (status === 'confirmed') {
            setPaymentStatus('success');
            setSuccessMessage('Payment Confirmed Successfully!');
            setErrorMessage('');
            clearInterval(interval);
          } else if (status === 'failed') {
            setPaymentStatus('failed');
            setErrorMessage('Payment Confirmation Failed. Please contact support.');
            clearInterval(interval);
          } else if (status === 'not_found') {
            setPaymentStatus('not_found');
            setErrorMessage('Payment Not Found.');
            clearInterval(interval);
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
          setErrorMessage('Error checking payment status. Please try again.');
        }
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [paymentId, paymentStatus]);

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      console.log('Submitting payment:', { name, phoneNumber, houseId, amountPaid });

      // Create a new payment entry
      const paymentCreationResponse = await axios.post(`https://backend.makejahunt.co.ke/api/api/create-payment`, {
        name,
        phoneNumber,
        houseId,
        amountPaid
      });

      const { paymentId } = paymentCreationResponse.data;
      console.log('Payment created with ID:', paymentId);
      setPaymentId(paymentId); // Set payment ID for polling

      // Show a success message indicating submission
      setSuccessMessage('Payment submitted successfully! Awaiting confirmation.');
    } catch (error) {
      console.error('Error processing payment:', error.response ? error.response.data : error.message);
      setErrorMessage('Error processing payment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="payment-page">
      {paymentId && !paymentStatus && (
        <div className="confirmation-overlay visible">
          <p>Confirming payment, please wait...</p>
          <div className="loading-spinner"></div>
        </div>
      )}
      <h1>Payment Page</h1>
      {errorMessage && <div className="message error-message">{errorMessage}</div>}
      {successMessage && <div className="message success-message">{successMessage}</div>}
      <div className="payment-info-box">
        <h2>Payment Information</h2>
        <p>Total Price:<strong> Kes: {price}</strong></p>
        <p>Till Number:<strong> 4713936</strong></p>
      </div>
      <div className="alert-box">
        <p>Please make sure you make the payment amount above and then submit the exact amount and your details below to avoid inconviniences.<br/> Once the payment is confirmed 
          our agent will contact you with the number you submit.
        </p>
      </div>
      <form onSubmit={handlePaymentSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={paymentStatus === 'success'}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            disabled={paymentStatus === 'success'}
          />
        </div>
        <div className="form-group">
          <label htmlFor="amountPaid">Amount Paid:</label>
          <input
            type="number"
            id="amountPaid"
            value={amountPaid}
            onChange={(e) => setAmountPaid(e.target.value)}
            required
            disabled={paymentStatus === 'success'}
          />
        </div>
        <div className="timer">
          <p>Time Remaining: {formatTime(timeLeft)}</p>
        </div>
        <button type="submit" disabled={isSubmitting || paymentStatus === 'success'}>
          Submit Payment
        </button>
      </form>
      {paymentStatus && (
        <button onClick={() => navigate('/')} className="home-button">
          Go to Home
        </button>
      )}
    </div>
  );
};

export default PaymentPage;