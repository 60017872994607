import React from 'react'

const VideoServices = () => {
  return (
    <div className="video-services-container">
      <header className="services-banner">
        <h1>Video Production Services</h1>
      </header>
      <div className="services-content">
        <p>
          We offer professional video production services including promotional videos, tutorials, and social media content. Let us help you bring your ideas to life with high-quality videos.
        </p>
        <h2>Contact Us</h2>
        <p>Email: makejahunt254@gmail.com</p>
        <p>Phone: +254-714234491</p>
      </div>
    </div>
  );
};
export default VideoServices

