import React from 'react'

const Logo = () => {
  return (
    <div>
        return (
        <div className="logo-services-container">
          <header className="services-banner">
            <h1>Logo Design Services</h1>
          </header>
          <div className="services-content">
            <p>
              Our logo design services help you create a memorable and professional brand identity. We specialize in custom logo designs tailored to your business needs.
            </p>
            <h2>Contact Us</h2>
            <p>Email: makejahunt254@gmail.com</p>
            <p>Phone: +254-714234491</p>
          </div>
        </div>
      );
    </div>
  )
}

export default Logo
