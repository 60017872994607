import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const HouseList = () => {
  const [houses, setHouses] = useState([]);
  const [location, setLocation] = useState('');
  const [type, setType] = useState('');
  const [availableOnly, setAvailableOnly] = useState(false);
  const [watchlist, setWatchlist] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showWatchlist, setShowWatchlist] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHouses = async () => {
      try {
        const result = await axios.get('https://backend.makejahunt.co.ke/api/houses');
        console.log(result.data); 
        setHouses(result.data);
      } catch (error) {
        console.error('Error fetching houses:', error);
      }
    };

    const fetchWatchlist = async () => {
      try {
        const result = await axios.get('https://backend.makejahunt.co.ke/api/watchlist');
        setWatchlist(result.data.watchlist);
      } catch (error) {
        console.error('Error fetching watchlist:', error);
      }
    };

    const fetchNotifications = async () => {
      try {
        const result = await axios.get('https://backend.makejahunt.co.ke/api/notifications');
        setNotifications(result.data.notifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchHouses();
    fetchWatchlist();
    fetchNotifications();
  }, []);

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleAvailabilityChange = (e) => {
    setAvailableOnly(e.target.checked);
  };

  const handleViewDetails = (id) => {
    if (!id) {
      console.error('House ID is undefined');
      return;
    }
    navigate(`/house/${id}`);
  };

  const filterHouses = () => {
    return houses.filter(house => {
      const matchesLocation = location ? house.location === location : true;
      const matchesType = type ? house.type === type : true;
      const matchesAvailability = availableOnly ? house.unitsAvailable > 0 : true;
      return matchesLocation && matchesType && matchesAvailability;
    });
  };

  const filteredHouses = filterHouses();

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const toggleWatchlist = () => {
    setShowWatchlist(!showWatchlist);
  };

  return (
    <div className="houselist">
      <header className="header">
        {/* Back Button with Arrow Icon */}
        <button className="back-button" onClick={() => navigate(-1)}>
          <ion-icon name="arrow-back-outline"></ion-icon>
        </button>
        <div>
          <label htmlFor="location-sort"><p>Location:</p> </label>
          <select className="hbtn" id="location-sort" value={location} onChange={handleLocationChange}>
            <option value="">All</option>
            <option value="Kathemboni">Kathemboni</option>
            <option value="Kathiani">Kathiani</option>
            <option value="Legends">Legends</option>
            <option value="Queens">Queens</option>
            <option value="Diaspora">Diaspora</option>
            <option value="Susana">Susana</option>
            <option value="Kwa-Chief">Kwa-Chief </option>
            <option value="Promise">Promise</option>
            <option value="Chinese">Chinese</option>
            <option value="University-Gate">University-Gate</option>
            <option value="Eastleigh">Eastleigh</option>
            <option value="Kathayoni">Kathayoni</option>
            <option value="Kwa-Tom">Kwa-Tom</option>
            <option value="CP">CP</option>
            <option value="katoloni">Katoloni</option>
          </select>
        </div>
        <div className="hbtn1" >
          <label htmlFor="type-sort"><p>Type:</p> </label>
          <select className="hbtn" id="type-sort" value={type} onChange={handleTypeChange}>
            <option value="">All</option>
            <option value="Bedsitter">Bedsitter</option>
            <option value="Single Room">Single Room</option>
            <option value="1 bedroom">1 Bedroom</option>
            <option value="2 bedroom">2 Bedroom</option>
          </select>
        </div>
        <div className='hbtn2'>
          <label htmlFor="availability-sort">
            <input
              type="checkbox"
              id="availability-sort"
              checked={availableOnly}
              onChange={handleAvailabilityChange}
            />
            Available
          </label>
        </div>

        {/* Watchlist Icon */}
        <div className="watchlist-icon" onClick={toggleWatchlist}>
          <ion-icon name="heart-outline"></ion-icon>
          {watchlist.length > 0 && <span className="watchlist-count">{watchlist.length}</span>}
        </div>

        {/* Notification Icon */}
        <div className="notification-icon" onClick={toggleNotifications}>
          <ion-icon name="notifications-outline"></ion-icon>
          {notifications.length > 0 && <span className="notification-count">{notifications.length}</span>}
        </div>
      </header>

      {/* Watchlist Dropdown */}
      {showWatchlist && (
        <div className="watchlist-dropdown">
          <h4>Your Watchlist</h4>
          {watchlist.length === 0 ? (
            <p>No houses in your watchlist.</p>
          ) : (
            <ul>
              {watchlist.map((house, index) => (
                <li key={index} onClick={() => handleViewDetails(house.id)}>
                  {house.name} - {house.location}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {/* Notification Dropdown */}
      {showNotifications && (
        <div className="notification-dropdown">
          <h4>Notifications</h4>
          {notifications.length === 0 ? (
            <p>No new notifications.</p>
          ) : (
            <ul>
              {notifications.map((notification, index) => (
                <li key={index}>{notification.message}</li>
              ))}
            </ul>
          )}
        </div>
      )}

      {filteredHouses.length === 0 ? (
        <p className="note">We are working to add more houses to this area.</p>
      ) : (
        <ul className="Hproperty">
          {filteredHouses.map((house) => (
            <li key={house.id}>
              <div className="Hproperty-card">
                <figure className="Hcard-banner">
                  {house.photos && (
                    <img src={`https://backend.makejahunt.co.ke/api/${house.photos[0]}`} alt="House" width="100%" />
                  )}
                 
                  <div className="Hbanner-actions">
                    <button className="Hbanner-actions-btn">
                      <ion-icon name="location"></ion-icon>
                      <address>{house.location}</address>
                    </button>
                  </div>
                </figure>
                <div className="Hcard-content">
                  <div className="Hcard-price">
                    <strong>Kes {house.rent}</strong>/Month
                  </div>

                  <h4 className="Hh3 card-title">
                    <p>{house.type}</p>
                  </h4>
                  <p className="Hcard-text">
                    {house.description}
                  </p>
                  <ul className="Hcard-list">
                    <li className="Hcard-item">
                      <strong>{house.unitsAvailable}</strong>
                      <ion-icon name="bed-outline"></ion-icon>
                      <span>Units</span>
                    </li>
                    <li className="Hcard-item">
                      <strong>{house.size}</strong>
                      <ion-icon name="square-outline"></ion-icon>
                      <span>Square Ft</span>
                    </li>
                  </ul>
                </div>
                <div className="Hcard-footer">
                  <div className="Hcard-footer-actions">
                    <button className="Hcard-footer-actions-btn">
                      <ion-icon name="resize-outline"></ion-icon>
                    </button>
                    <button onClick={() => handleViewDetails(house.id)}>
                      <p className="btn">Details</p>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HouseList;