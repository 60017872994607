import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home.js';
import HouseList from './pages/HouseList';
import HouseDetails from './pages/HouseDetails.js'
import Payment from './pages/payment';
import AddHouse from './pages/addHouse.js';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Adminconfirmation from './pages/Adminconfirmation';
import AttendancePage from './pages/AttendancePage.js';
import AdminSelectionPage from './pages/AdminSelectionPage.js';
import NotAuthorized from './pages/NotAuthorised.js';
import Terms from './pages/Terms.js';
import VideoServices from './pages/VideoServices.js';
import WebServices from './pages/WebServices.js';
import SecondHandItems from './pages/SecondHandItems.js';
import LogoServices from './pages/Logo.js';

import ProtectedRoute from './pages/ProtectedRoute'; 

import "./style.css";


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user');

    if (token && userData) {
      setIsAuthenticated(true);
      setUser(JSON.parse(userData));
    }
  }, []);

  return (
    <div className='app'>
      <Router>
        <div>
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} setUser={setUser} />} />
            <Route path="/" element={<Home isAuthenticated={isAuthenticated} user={user} />} />
            <Route path="/HouseList" element={<HouseList />} />
            <Route path="/house/:id" element={<HouseDetails />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/NotAuthorised" element={<NotAuthorized />} />
            <Route path="/AdminConfirmation" element={<Adminconfirmation/>}/>
            <Route path="/Terms" element={<Terms/>}/>

            <Route path="/VideoServices" element={<VideoServices/>}/>
            <Route path="/SecondHandItems" element={<SecondHandItems/>}/>
            <Route path="/WebServices" element={<WebServices/>}/>
            <Route path="/logoServices" element={<LogoServices/>}/>

            {/* Protected Routes for Admin */}
            <Route path="/addHouse" element={
              <ProtectedRoute isAuthenticated={isAuthenticated} isAdmin={user?.isAdmin}>
                <AddHouse />
              </ProtectedRoute>
            } />

            

            <Route path="/AttendancePage" element={
              <ProtectedRoute isAuthenticated={isAuthenticated} isAdmin={user?.isAdmin}>
                <AttendancePage />
              </ProtectedRoute>
            } />

            <Route path="/AdminSelectionPage" element={
              <ProtectedRoute isAuthenticated={isAuthenticated} isAdmin={user?.isAdmin}>
                <AdminSelectionPage />
              </ProtectedRoute>
            } />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;