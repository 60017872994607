import React from 'react';


const Profile = ({ user, onClose }) => {
  return (
    <div className="profile-overlay">
      <div className="profile-popup">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>Profile</h2>
        <p><strong>Name:</strong> {user.firstname} {user.lastname}</p>
        <p><strong>Username:</strong> {user.username}</p>
        <p><strong>PhoneNumber:</strong> {user.phoneNumber}</p>
      </div>
    </div>
  );
};

export default Profile;