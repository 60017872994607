import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsAuthenticated, setUser }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(`https://backend.makejahunt.co.ke/api/login`, { username, password });
      const { token, user } = response.data;
  
      // Ensure isAdmin is interpreted as a boolean
      const isAdmin = user.isAdmin === true; // Explicit check for boolean
  
      console.log('Full response data:', response.data);
      console.log('Is user an admin?', isAdmin);
  
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
  
      setMessage('Login successful');
      setIsAuthenticated(true);
      setUser(user);
  
      // Redirect based on the user type
      if (isAdmin) {
        navigate('/AdminSelectionPage');
      } else {
        navigate('/');
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred');
    }
  };
  
  

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="loginregisterbody">
      <div className="wrapper">
        <div className="form-box">
          <div name="login-container" className="login-container" id="login">
            <div className="top">
              <span onClick={() => navigate('/register')} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>
                Don't have an account? Sign Up
              </span>
              <h2>Login</h2>
            </div>

            <form onSubmit={handleLogin}>
              <div className="input-box">
                <input
                  value={username}
                  id="username"
                  type="text"
                  className="input-field"
                  placeholder="Username or Phone Number"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <i className="bx bx-user"></i>
              </div>
              <div className="input-box">
                <input
                  id="password"
                  type={passwordVisible ? 'text' : 'password'} 
                  className="input-field"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <i className="bx bx-lock-alt"></i>
                <button
                  type="button"
                  className="toggle-password"
                  onClick={togglePasswordVisibility}
                >
                  <ion-icon name={passwordVisible ? 'eye-off-outline' : 'eye-outline'}></ion-icon>
                </button>
              </div>
              <div className="input-box">
                <button type="submit" id="loginbtn" className="submit" value="Sign In">Sign in</button>
              </div>
              <div className="two-col">
                <div className="one">
                  <input type="checkbox" id="login-check" />
                  <label htmlFor="login-check"> Remember Me</label>
                </div>
                <div className="two">
                  <label><a href="/forgot-password">Forgot password?</a></label>
                </div>
              </div>
            </form>
            <div className="login-message">
              {message && <p>{message}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

